import { Box, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const ResponsibleGamingPolicy = () => {

  return (
    <Box maxW="800px" mx="auto" p={4} >
      <Text size="title" mb="30px" textAlign="left">
        Rhino Studios Inc. Responsible Gaming Policy
      </Text>

      <Text mb="30px" fontWeight="500">
        At Rhino Studios Inc., we are committed to fostering a responsible gaming environment for all
        of our users. As a company, we recognize the importance of ensuring that our products are
        enjoyed in a safe, fair, and responsible manner. We aim to promote healthy gaming habits while
        providing resources and support for those who may be affected by gambling-related harm.
        This Responsible Gaming Policy outlines the principles, guidelines, and practices that Rhino
        Studios Inc. follows to promote safe and responsible gaming. Our goal is to create a positive,
        enjoyable experience for all of our customers, while safeguarding vulnerable individuals from
        harm.
      </Text>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          1. Commitment to Fair Play
        </Text>
        <Text mb="20px" fontWeight="500">
          We are dedicated to providing a fair and transparent gaming environment. Our games are
          designed with fairness in mind, and we use trusted random number generators (RNGs) to
          ensure that outcomes are random and unbiased. We are committed to compliance with all
          relevant laws and regulations governing our industry to maintain the integrity of our products.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          2. Age Restrictions
        </Text>
        <Text mb="10px" fontWeight="500">
          Rhino Studios Inc. adheres to a strict age verification policy. We do not permit access to our
          products or services by individuals under the legal gambling age as defined by local laws and
          regulations. Users must be of legal age to participate in any form of gaming or wagering. We
          implement age verification systems to ensure compliance and to prevent underage access.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          3. Setting Limits
        </Text>
        <Text mb="10px" fontWeight="500">
          We provide users with the option to set personalized limits on their gaming activities, including:
        </Text>
        <UnorderedList mb="10px" pl="10px" fontWeight="500" spacing={1}>
          <ListItem>
            <Box as="span" fontWeight="800">Deposit Limits:</Box>{' '}
            Users can set daily, weekly, or monthly deposit limits to control their
            spending.
          </ListItem>
          <ListItem>
            <Box as="span" fontWeight="800">Wagering Limits:</Box>{' '}
            Users can set limits on the amount they bet or wager per session or
            over a period of time.
          </ListItem>
          <ListItem>
            <Box as="span" fontWeight="800">Session Time Limits:</Box>{' '}
            Users can set time limits on how long they play during a single
            session.
          </ListItem>
          <ListItem>
            <Box as="span" fontWeight="800">Loss Limits:</Box>{' '}
            Users can set a maximum amount they are willing to lose over a specified
            time period.
          </ListItem>
        </UnorderedList>
        <Text mb="10px" fontWeight="500">
          These tools are designed to help players manage their gambling activities and prevent
          excessive gaming.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          4. Self-Exclusion
        </Text>
        <Text mb="20px" fontWeight="500">
          Rhino Studios Inc. offers a self-exclusion program that allows users to voluntarily suspend or
          exclude themselves from accessing our services for a specified period of time. Users can select
          different exclusion durations (e.g., 24 hours, one week, one month, or longer), and during this
          period, they will not be able to access their accounts or engage in any gaming activities.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          5. Identifying Problem Gambling
        </Text>
        <Text mb="10px" fontWeight="500">
          We are committed to identifying and addressing problem gambling behaviors. We encourage
          users to monitor their gaming activity and be aware of signs of problematic behavior. Signs of
          problem gambling may include:
        </Text>
        <UnorderedList mb="10px" pl="10px" fontWeight="500" spacing={1}>
          <ListItem>
            Spending more time or money on gaming than intended.
          </ListItem>
          <ListItem>
            Neglecting other responsibilities, such as work or personal relationships, due to gaming.
          </ListItem>
          <ListItem>
            Chasing losses or feeling the need to keep gambling to recover lost money.
          </ListItem>
          <ListItem>
            Feeling distressed or anxious about gambling activities.
          </ListItem>
        </UnorderedList>
        <Text mb="10px" fontWeight="500">
          If any of these signs are recognized, we encourage users to seek assistance from our support
          team, use our self-exclusion tools, or reach out to external organizations that specialize in
          problem gambling support.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          6. Support for Problem Gambling
        </Text>
        <Text mb="10px" fontWeight="500">
          If users feel they are developing a gambling problem, we encourage them to seek help. Rhino
          Studios Inc. provides information and links to professional support organizations such as:
        </Text>
        <UnorderedList mb="10px" pl="10px" fontWeight="500" spacing={1}>
          <ListItem>
            <Box as="span" fontWeight="800">
              National Council on Problem Gambling (NCPG)
            </Box>{' '}
            (or the relevant local gambling
            support organization)
          </ListItem>
          <ListItem>
            <Box as="span" fontWeight="800">Gamblers Anonymous</Box>
          </ListItem>
          <ListItem>
            <Box as="span" fontWeight="800">Gambling Therapy</Box>{' '}
            (international support)
          </ListItem>
        </UnorderedList>
        <Text mb="10px" fontWeight="500">
          Our customer support team is also available to assist users with any queries related to
          responsible gaming and can provide guidance on the best course of action if a user feels they
          need help.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          7. Promoting Healthy Gambling Habits
        </Text>
        <Text mb="10px" fontWeight="500">
          We believe that gaming should be an enjoyable, recreational activity. T o promote responsible
          gambling, we offer the following guidance to our users:
        </Text>
        <UnorderedList mb="10px" pl="10px" fontWeight="500" spacing={1}>
          <ListItem>
            Always play within your means and set limits on your spending and gaming time.
          </ListItem>
          <ListItem>
            Take regular breaks and avoid long, uninterrupted gaming sessions.
          </ListItem>
          <ListItem>
            Remember that gaming is a form of entertainment, not a way to make money.
          </ListItem>
          <ListItem>
            Never gamble to escape from personal or financial issues.
          </ListItem>
        </UnorderedList>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          8. Education and Awareness
        </Text>
        <Text mb="20px" fontWeight="500">
          Rhino Studios Inc. is dedicated to raising awareness of responsible gaming practices. We will
          continue to educate our users on the risks of problem gambling, the importance of self-control,
          and available resources for those who may need help. We provide responsible gaming
          information on our website, within our games, and through communication channels with our
          customers.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          9. Data Protection and Confidentiality
        </Text>
        <Text mb="20px" fontWeight="500">
          We respect our users' privacy and ensure that all data related to responsible gaming activities,
          such as self-exclusion requests or limit settings, is treated confidentially. We comply with all data
          protection laws and regulations to ensure the security and privacy of our users’ personal
          information.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          10. Ongoing Commitment
        </Text>
        <Text mb="10px" fontWeight="500">
          Rhino Studios Inc. continuously reviews and updates its responsible gaming practices to align
          with best practices, industry standards, and changes in laws and regulations. We are committed
          to ensuring that responsible gaming is a core part of our corporate values and that our users are
          provided with the tools and support they need to enjoy gaming safely and responsibly.
        </Text>
        <Text mb="10px" fontWeight="500">
          If you have any questions about our Responsible Gaming Policy or need assistance, please
          contact our customer support team at{' '}
          <Link to="mailto:info@frankmichaelsmith.com">info@frankmichaelsmith.com</Link>{' '}
          or visit{' '}
          <Link to="https://solosurvivor.co/" target='_blank'>solosurvivor.co</Link>.
        </Text>
        <Text mb="10px" fontWeight="500">
          Thank you for playing responsibly with Rhino Studios Inc.
        </Text>
        <Text mb="10px" fontWeight="500">
          This Responsible Gaming Policy is effective as of 12/12/24 and will be reviewed periodically to ensure continued relevance and effectiveness.
        </Text>
      </Box>
    </Box>
  );
};

export default ResponsibleGamingPolicy;
