import { Box, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const PolicyPage = () => {

  return (
    <Box maxW="800px" mx="auto" p={4} >
      <Text size="title" mb="30px" textAlign="left">Privacy Policy</Text>

      <Text mb="30px" fontWeight="500">
        Welcome to Solo SUrvivor! Rhino Studios Inc. is the owner of solosurvivor.co (“Solo Survivor,”
        “we” and “us”) and we care about your privacy. We have developed this Privacy Policy (this
        “Policy”) to describe the information we collect, how that information may be used, with whom it
        may be shared, and your choices, in connection with your use of our website, app, and contests
        (collectively, “Games”), and any of our products and services that link to this Policy (collectively,
        our “Services.”).
      </Text>

      <Text mb="30px" fontWeight="500">Please read this Policy carefully before using our Services.</Text>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          INFORMATION WE COLLECT
        </Text>
        <Text mb="20px" fontWeight="500">
          We collect information in a few ways, so we have broken things down for you below to explain
          the types of information that we collect in each context.
        </Text>

        <Text mb="10px" fontWeight="800">
          Information You Provide to Us
        </Text>
        <Text mb="10px" fontWeight="800">
          Your Account Information:
        </Text>
        <Text mb="20px" fontWeight="500">
          When you use our Services, you may provide certain information directly to us. For example,
          when you register for an account with SOLO SURVIVOR or play our Games, we require that
          you provide us with your name, a valid phone number, mailing address, and email address. You
          may also create a username. Once you create an account, we collect any additional information
          that you provide to your account or provide to us through your account. When you play our
          Games, we collect your activity in the Games, such as your entries and your entry history.
        </Text>

        <Text mb="10px" fontWeight="800">Your Communication with Us:</Text>
        <Text mb="10px" fontWeight="500">
          The personal information we collect from you generally may include:
        </Text>
        <UnorderedList mb="20px" pl="10px" fontWeight="500" spacing={1}>
          <ListItem>Your name</ListItem>
          <ListItem>Email address</ListItem>
          <ListItem>Date of birth</ListItem>
          <ListItem>Username</ListItem>
          <ListItem>Location</ListItem>
          <ListItem>Your preferences</ListItem>
          <ListItem>Submissions to various competitions and promotions</ListItem>
          <ListItem>Communications sent to us by you</ListItem>
          <ListItem>Any other information you submit to Solo Survivor when choosing to participate in various activities on the
            Services.</ListItem>
        </UnorderedList>

        <Text mb="10px" fontWeight="500">
          In addition to the above, we may need to verify your identity for you to use some aspects of the
          Services. For purposes of verification, we may also collect the following personal information
          from you:
        </Text>
        <UnorderedList mb="20px" pl="10px" fontWeight="500" spacing={1}>
          <ListItem>Passport information</ListItem>
          <ListItem>Driving license information</ListItem>
          <ListItem>Other identification documents</ListItem>
          <ListItem>Permanent and temporary address</ListItem>
          <ListItem>Tax-related information</ListItem>
          <ListItem>
            Other information may be required to verify you in accordance with applicable laws and regulations.
          </ListItem>
        </UnorderedList>

        <Text mb="10px" fontWeight="800">
          Information We Automatically Collect
        </Text>
        <Text mb="10px" fontWeight="500">
          As with most websites, when you use our Services we automatically receive and collect
          information about you and your device. This information includes the following:
        </Text>
        <UnorderedList mb="20px" pl="10px" fontWeight="500" spacing={1}>
          <ListItem>
            Information about your device, such as the operating system, hardware, system version,
            the Internet Protocol (IP) address, device ID, and device language.
          </ListItem>
          <ListItem>
            The specific actions that you take when you use our Services, including but not limited to
            the pages and screens that you view or visit, search terms that you enter, how you
            interact with our Services, and information about your interactions with the Services.
          </ListItem>
          <ListItem>Location information, such as GPS information.</ListItem>
          <ListItem>
            Location information, such as GPS information.
          </ListItem>
          <ListItem>
            Information regarding your interaction with email messages, for example, whether you
            opened, clicked on, or forwarded the email message.
          </ListItem>
          <ListItem>
            Identifiers associated with cookies or other technologies that may uniquely identify your
            device or browser (as further described below)
          </ListItem>
          <ListItem>Pages you visited before or after navigating to our website.</ListItem>
        </UnorderedList>

        <Text mb="10px" fontWeight="800">
          Cookies and Analytics
        </Text>
        <Text mb="10px" fontWeight="500">
          As most websites do, we use analytics partners to collect certain information about how our
          Services are used. We and our analytics partners use cookies, web beacons, and other
          technologies to receive and store certain types of information whenever you interact with our
          Services through your computer or mobile device. A cookie is a small file containing a string of
          characters that is sent to your computer when you visit a website. When you visit the website
          again, the cookie allows that site to recognize your browser. Cookies may store unique
          identifiers, user preferences, and other information. You can reset your browser to refuse all
          cookies or to indicate when a cookie is being sent. However, some website features or services
          may not function properly without cookies. We use cookies to analyze and improve our services,
          store user preferences, track user trends, and provide relevant advertising to you.
        </Text>
        <Text mb="10px" fontWeight="500">
          We may use third parties, such as Google Analytics or other analytics providers, to analyze
          traffic to our website. Google Analytics does not create individual profiles for visitors and only
          collects aggregate data. To disable Google Analytics, please download the browser add-on for
          the deactivation of Google Analytics provided by Google at{' '}
          <Link to="http://tools.google.com/dlpage/gaoptout?hl=en" target='_blank'>Google Analytics Opt-out Browser Add-on</Link>.
          To learn more about privacy and Google Analytics, please consult the Google Analytics overview provided by Google at{' '}
          <Link to="http://www.google.com/intl/en/analytics/privacyoverview.html" target='_blank'>Google Marketing Platform - Unified Advertising and Analytics</Link>.
        </Text>
        <Text mb="10px" fontWeight="500">
          We may also use cookies, pixels, beacons, or other web-tracking technologies to track the
          amount of time spent on our Services and whether or not certain content was viewed. We may
          work with a third party to collect and process this information for us, based on our instructions
          and in compliance with this Policy.
        </Text>
        <Text mb="20px" fontWeight="500">
          At present, our Services do not respond to “Do Not Track” signals or similar mechanisms.
        </Text>

        <Text mb="10px" fontWeight="800">Interest-Based Advertising</Text>
        <Text mb="10px" fontWeight="500">
          We participate in interest-based and behavioral advertising. We use third-party advertising
          partners to display ads tailored to your interests based on how you browse and shop online, as
          well as to provide advertising-related services such as ad delivery, reporting, attribution,
          analytics, and market research.
        </Text>
        <Text mb="10px" fontWeight="500">
          We allow third-party companies to collect certain information when you visit our website. This
          information is used to serve ads for Solo Survivor products or services, or for the products or
          services of other companies when you visit our website or other websites. These companies
          use non-identifiable information (e.g., click stream information, browser type, time and date,
          subject of advertisements clicked or scrolled over hardware/software information, and session
          ID) and personally identifiable information (e.g., static IP address) during your visits to this and
          other websites to provide advertisements about goods and services likely to be of greater
          interest to you. These parties typically use a cookie, web beacon, or other similar tracking
          technologies to collect this information.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          HOW WE USE YOUR INFORMATION
        </Text>
        <Text mb="10px" fontWeight="500">
          In general, we collect information from you so that we can provide our Services, operate
          our business, and provide information that you request from us. This includes the
          following uses and purposes:
        </Text>
        <UnorderedList mb="10px" pl="10px" fontWeight="500" spacing={1}>
          <ListItem>Create and administer your account.</ListItem>
          <ListItem>
            Facilitate our Games, including entry, winner selection, and awarding and fulfillment of
            prizes.
          </ListItem>
          <ListItem>Provide, operate, improve, maintain, and protect our Services.</ListItem>
          <ListItem>Provide you with technical and other support.</ListItem>
          <ListItem>Send you Services and company updates, marketing communication, service
            information, and other information about SOLO SURVIVOR and our Services, and
            products and services of third parties, that we think you may be interested in.</ListItem>
          <ListItem>Conduct research and analysis, and monitor and analyze trends and usage.</ListItem>
          <ListItem>
            Enhance or improve user experience, our business, and our services, including the
            safety and security thereof.
          </ListItem>
          <ListItem>Personalize our services to you by, for example, customizing content that you see.</ListItem>
          <ListItem>Communicate with you and respond to inquiries.</ListItem>
          <ListItem>
            Operate our business and perform any other function that we believe in good faith is
            necessary to protect the security or proper functioning of our Services.
          </ListItem>
          <ListItem>
            As necessary to comply with any applicable law, regulation, subpoena, legal process, or
            governmental request.
          </ListItem>
          <ListItem>
            Enforce contracts and applicable Terms of Service and Games Official Rules, including
            investigation of potential violations thereof.
          </ListItem>
          <ListItem>Detect, prevent, or otherwise address fraud, security or technical issues.</ListItem>
          <ListItem>
            Protect against harm to the rights, property or safety of SOLO SURVIVOR, our users,
            customers, or the public as required or permitted by law.
          </ListItem>
        </UnorderedList>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          HOW WE SHARE YOUR INFORMATION
        </Text>
        <Text mb="20px" fontWeight="500">
          Like most companies, we share information in certain circumstances with third parties through
          the operation of our Services and our business. Below we explain when that happens.
        </Text>
        <Text mb="10px" fontWeight="800">Service Providers</Text>
        <Text mb="20px" fontWeight="500">
          We use third parties to assist us with operating our business and providing our Services, such
          as our technology vendors that help us maintain our Services and partners that assist us with
          our marketing and communication. These service providers will have access to your information
          to provide services to us.
        </Text>
        <Text mb="10px" fontWeight="800">Other Players</Text>
        <Text mb="20px" fontWeight="500">
          Your username may be visible to other users in the Services. For example, if you are on our
          Game leaderboard, your username will be displayed and visible to other users.
        </Text>
        <Text mb="10px" fontWeight="800">As Directed By You and With Your Consent</Text>
        <Text mb="20px" fontWeight="500">
          We share information with companies, organizations or individuals outside of SOLO SURVIVOR
          at your direction or when we have your consent to do so.
        </Text>
        <Text mb="10px" fontWeight="800">Legal Proceedings</Text>
        <Text mb="10px" fontWeight="500">
          We may share information with third-party companies, organizations, governmental authorities,
          or individuals outside of SOLO SURVIVOR if we have a good-faith belief that access, use,
          preservation, or disclosure of the information is reasonably necessary to:
        </Text>
        <UnorderedList mb="20px" pl="10px" fontWeight="500" spacing={1}>
          <ListItem>Meet any applicable law, regulation, subpoena, legal process, or governmental request</ListItem>
          <ListItem>Enforce a contract, including but not limited to any applicable Terms of Service or Games
            Official Rules, including investigation of potential violations thereof</ListItem>
          <ListItem>
            Detect, prevent, or otherwise address fraud, security, or technical issues
          </ListItem>
          <ListItem>
            Protect against harm to the rights, property, or safety of Solo Survivor, our users,
            customers, or the public as required or permitted by law.
          </ListItem>
        </UnorderedList>
        <Text mb="10px" fontWeight="800">Sale or Merger</Text>
        <Text mb="20px" fontWeight="500">
          We may share information about you as part of a merger or acquisition. If Rhino Studios Inc. is
          involved in a merger, asset sale, financing, liquidation or bankruptcy, or acquisition of all or
          some portion of our business to another company, we may share your information with that
          company before and after the transaction closes. In such a case, unless permitted or otherwise
          directed by applicable law, your information would remain subject to the terms of the applicable
          privacy policy in effect at the time of such transfer.
        </Text>
        <Text mb="10px" fontWeight="800">Aggregate Information</Text>
        <Text mb="20px" fontWeight="500">
          We may de-identify or aggregate information so that you are not identified as an individual, and
          use and provide that information to third parties without restriction. We may also provide
          aggregate usage information to third parties (or allow third parties to collect that information
          from you), who may use such information to understand how often and in what ways people use
          our Services.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          CHOICES ABOUT YOUR INFORMATION
        </Text>
        <Text mb="20px" fontWeight="500">
          We strive to provide you with choices with respect to your information. Also, remember, you can
          opt not to disclose certain information to us - but keep in mind some information may be needed
          to create a SOLO SURVIVOR account, play our Games, or take advantage of some of our
          Services and features.
        </Text>
        <Text mb="10px" fontWeight="800">Marketing</Text>
        <Text mb="20px" fontWeight="500">
          You may unsubscribe from SOLO SURVIVOR marketing communications at any time by
          following the “unsubscribe” link at the bottom of any such communication. Most promotional
          communications will also offer recipients choices about receiving additional messages.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          USERS
        </Text>
        <Text mb="20px" fontWeight="500">
          Our Services are not directed at children under 18, and we do not knowingly collect information
          from children under 18. If you are under 18, please do not attempt to use our Services, play our
          Games, or send any information about yourself to us.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          SECURITY OF YOUR INFORMATION
        </Text>
        <Text mb="20px" fontWeight="500">
          We use reasonable security measures, including measures designed to protect against
          unauthorized or unlawful processing and against accidental loss, destruction, or damage to your
          information. We also take certain measures to enhance the security of our Services, however,
          since the Internet is not a 100% secure environment, we cannot guarantee, ensure, or warrant
          the security of any information you transmit to us. There is no guarantee that information may
          not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or
          managerial safeguards. It is your responsibility to protect the security of your login information.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          RETENTION OF INFORMATION
        </Text>
        <Text mb="20px" fontWeight="500">
          We retain information in accordance with applicable laws. The length of time we keep
          information depends on the type of information and whether we have an ongoing business or
          legal need to retain it (for example, if you have an account with us, or to comply with applicable
          legal, tax, or accounting requirements).
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          THIRD-PARTY WEBSITES AND SERVICES
        </Text>
        <Text mb="20px" fontWeight="500">
          Our Services may contain links to other websites and services operated by third parties and
          may include social media features such as Snapchat and Instagram buttons or links. These
          third-party websites and services may collect information about you if you click on a link or visit
          those websites or services, and the social media sites may automatically record information
          about your browsing behavior every time you visit a website that has a social media button. Your
          interactions with these features and third parties are governed by the privacy policy of the third
          party, not by this Policy.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          CHANGES TO THIS POLICY
        </Text>
        <Text mb="20px" fontWeight="500">
          We may make changes to this Policy from time to time. When we do, we will post the updated
          version on this page. We encourage you to read this page each time that you use our Services
          so that you will be aware of any changes, and your continued use of our Services shall
          constitute your acceptance of any such changes. Changes to this Policy take effect from the
          date of publication unless stated otherwise.
        </Text>
      </Box>

      <Box mb="30px">
        <Text fontWeight="800" mb="20px">
          CONTACT US
        </Text>
        <Text mb="20px" fontWeight="500">
          If you have any comments, questions, concerns, or suggestions about this Policy, or about our
          privacy practices in general, please contact us at <Link to="mailto:info@frankmichaelsmith.com">info@frankmichaelsmith.com</Link>.
        </Text>
      </Box>
    </Box>
  );
};

export default PolicyPage;
